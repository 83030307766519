export const techs = [
    'Ruby on Rails',
    'ReactJS',
    'Javascript',
    'VueJS',
    'SQL',
    'MongoDB',
    'Elixer',
    'JQuery',
    'Coffeescript'
];

export default techs;